:root {
  --color1: #152627;
  --color2: #3c6e71;
  --color3: #cccfbe;
  --color4: #264b63;

  --color5: #d9d9d9;
  --color6: #779ea0;

  --color-error: red;

  --font-main: degular-display;
  --font-comp: sofia-pro;

  --shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

body {
  font-family: var(--font-comp);
}

.App {
  width: 100vw;
  max-width: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto;
  grid-template-areas:
    "landing landing"
    "info-left info-right"
    "newsletter newsletter"
    "footer footer";
}

.landing {
  background-color: var(--color1);
  background-image: url("images/background.jpg");
  background-size: cover;
  height: 100vh;
  grid-area: landing;
  display: flex;
  flex-direction: column;
}

.topbar {
  position: absolute;
  display: flex;
  justify-content: flex-start;
  align-content: center;
  margin: 30px;
  left: 0;
  right: 0;
  top: 0;
}

.topbar div {
  margin: 0px 20px;
}

.topbar div:nth-child(2) {
  margin-left: auto;
}

.language-switcher {
  display: flex;
  align-items: center;
}

.flag {
  display: block;
  cursor: pointer;
  margin: 0px 5px;
  border-radius: 50%;
  box-shadow: var(--shadow);
}

.button-signup {
  color: var(--color3);
  font-size: 20px;
  background-color: #061928;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: var(--shadow);
}

button:hover {
  filter: brightness(130%);
}

.landing-text {
  margin: auto;
}

h1 {
  font-family: var(--font-main);
  color: var(--color3);
  font-size: 90px;
  font-weight: 500;
  text-align: center;
  margin: 10px;
  text-shadow: var(--shadow);
}

h2 {
  font-family: var(--font-main);
  font-size: 32px;
}

.logo {
  cursor: pointer;
}

#subtitle {
  color: #d9d9d9;
  font-size: 32px;
  text-align: center;
  text-shadow: var(--shadow);
}

.information {
  height: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  padding: 10px;
  box-sizing: border-box;
}

#left-info {
  background-color: var(--color3);
  grid-area: info-left;
  color: var(--color4);
}

#right-info {
  background-color: var(--color1);
  grid-area: info-right;
  color: var(--color6);
}

.newsletter {
  background-color: var(--color5);
  height: 55vh;
  grid-area: newsletter;
  color: var(--color4);
  display: flex;
  justify-content: space-evenly;
  padding: 10px;
  box-sizing: border-box;
}

#phone-image {
  height: 100%;
}

#kataja-logo-text {
  font-family: var(--font-comp);
  font-weight: bold;
  font-size: 30px;
}

.footer {
  display: inline-block;
  background-color: var(--color1);
  height: 5vh;
  line-height: 5vh;
  grid-area: footer;
  color: var(--color3);
  text-align: center;
}

.newsletter-button-signup {
  color: var(--color3);
  font-size: 20px;
  background-color: #061928;
  height: 52px;
  border-radius: 8px;
  padding: 0px 20px;
  margin-left: 5px;
  cursor: pointer;
  box-shadow: var(--shadow);
  border: none;
  white-space: nowrap;
}

input[type="email"] {
  height: 52px;
  width: 100%;
  box-sizing: border-box;
  background-color: var(--color3);
  border: 2px solid var(--color2);
  font-family: var(--font-comp);
  font-size: 20px;
  color: var(--color4);
  padding: 0px 20px;
  border-radius: 8px;
  box-shadow: var(--shadow);
}

.field {
  width: 100%;
}

.newsletter-form {
  display: flex;
}

.newsletter-signup {
  margin: auto 0px;
}

.newsletter-signup > div {
  margin: 20px 0px;
}

.spinner {
  margin: 10px 0px;
}

a {
  color: var(--color2);
}

.form-error {
  margin: 5px 0px;
  color: var(--color-error);
}

.signup-success {
  font-size: 20px;
  font-weight: bold;
}

.error-graphql {
  margin: 5px 0px;
  color: var(--color-error);
}
